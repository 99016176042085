@import '../../styles/brand-colors';

.modal-module_small__3PkJI {
  height: 550px !important;
  width: 750px !important;
}

.ua-properties {
  padding-top: 5px;
  height: 500px;
  overflow: scroll;
}

.ua-input-group {
  padding-bottom: 3px;
  display: flex;

  label {
    width: 150px;
  }

  .number-input {
    padding: 0 10px;
    width: 370px;
  }

  .link-button {
    width: 210px;
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }

  button {
    margin: 0 2px;
    padding-top: 5px;
    width: 50px;
    height: 35px;
  }
}

.missing-data-warning {
  padding: 5px;
  margin: 20px;
  border-radius: 10px;
  display: flex;
  background-color: darkred;
  p {
    margin: 3px;
  }
}

.unit-info {
  width: 730px;
  .unit-key {
    width: 150px;
    font-weight: 700;
  }
  .unit-value {
    width: 400px;
  }
  .select-cohort-button {
    width: 100px
  }
}

.cohort-match {
  border-style: solid;
  border-radius: 3px;
  border-color: darkgreen;
}

.cohort-mismatch {
  border-style: solid;
  border-radius: 3px;
  border-color: darkred;
}
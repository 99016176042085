@import '../../styles/brand-colors';

.admin-utility-row {
  display: flex;
  width: 100%;
  padding-bottom: 3px;

  .admin-alert-container {
    width: 20%;
    height: 40px; // components should not shift the page when they have something to display such as an alert
    display: flex;
    padding: 0 20px !important;
  }

  .admin-search-input {
    width: 40%;
    display: flex;
    flex-direction: row;

    .admin-search {
      width: 80%
    }
  }

}

.admin-select {
  width: 200px;
}

.admin-input-group {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;

  .admin-input {
    width: 350px;
  }

  .admin-action-input {
    width: 275px;
  }
}

.admin-list {
  min-height: 200px !important;
}

.admin-list-table {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  overflow: scroll;
  width: 1000px;
  height: 580px;

  .virtualized-table-standard {
    height: 515px;
  }
}

.admin-option-table {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  overflow: scroll;
  width: 1000px;
  height: 500px;

  .virtualized-table-standard {
    height: 435px;
  }
}

.admin-loading {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  min-height: 460px;
}

.success {
  background-color: green;

  path {
    fill: white !important;
  }
}

.warning {
  background-color: goldenrod;
  path {
    fill: white !important;
  }
}

.danger {
  background-color: darkred;
  path {
    fill: white !important;
  }
}